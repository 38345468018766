exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-dla-klienta-js": () => import("./../../../src/pages/dla-klienta.js" /* webpackChunkName: "component---src-pages-dla-klienta-js" */),
  "component---src-pages-dla-opiekunki-etapy-rekrutacji-opiekunki-osob-starszych-js": () => import("./../../../src/pages/dla-opiekunki/etapy-rekrutacji-opiekunki-osob-starszych.js" /* webpackChunkName: "component---src-pages-dla-opiekunki-etapy-rekrutacji-opiekunki-osob-starszych-js" */),
  "component---src-pages-dla-opiekunki-oferty-pracy-dla-opiekunki-osob-starszych-js": () => import("./../../../src/pages/dla-opiekunki/oferty-pracy-dla-opiekunki-osob-starszych.js" /* webpackChunkName: "component---src-pages-dla-opiekunki-oferty-pracy-dla-opiekunki-osob-starszych-js" */),
  "component---src-pages-dla-opiekunki-pytania-i-odpowiedzi-js": () => import("./../../../src/pages/dla-opiekunki/pytania-i-odpowiedzi.js" /* webpackChunkName: "component---src-pages-dla-opiekunki-pytania-i-odpowiedzi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instrukcja-js": () => import("./../../../src/pages/instrukcja.js" /* webpackChunkName: "component---src-pages-instrukcja-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kursy-index-js": () => import("./../../../src/pages/kursy/index.js" /* webpackChunkName: "component---src-pages-kursy-index-js" */),
  "component---src-pages-kursy-lista-js": () => import("./../../../src/pages/kursy/lista.js" /* webpackChunkName: "component---src-pages-kursy-lista-js" */),
  "component---src-pages-o-nas-firma-js": () => import("./../../../src/pages/o-nas/firma.js" /* webpackChunkName: "component---src-pages-o-nas-firma-js" */),
  "component---src-pages-o-nas-kariera-js": () => import("./../../../src/pages/o-nas/kariera.js" /* webpackChunkName: "component---src-pages-o-nas-kariera-js" */),
  "component---src-pages-o-nas-nasz-zespol-js": () => import("./../../../src/pages/o-nas/nasz-zespol.js" /* webpackChunkName: "component---src-pages-o-nas-nasz-zespol-js" */),
  "component---src-pages-o-nas-opinie-i-certyfikaty-js": () => import("./../../../src/pages/o-nas/opinie-i-certyfikaty.js" /* webpackChunkName: "component---src-pages-o-nas-opinie-i-certyfikaty-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-regulamin-zakupow-js": () => import("./../../../src/pages/regulamin-zakupow.js" /* webpackChunkName: "component---src-pages-regulamin-zakupow-js" */),
  "component---src-pages-zgody-js": () => import("./../../../src/pages/zgody.js" /* webpackChunkName: "component---src-pages-zgody-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-job-offer-for-nanny-details-js": () => import("./../../../src/templates/JobOfferForNannyDetails.js" /* webpackChunkName: "component---src-templates-job-offer-for-nanny-details-js" */),
  "component---src-templates-news-post-template-js": () => import("./../../../src/templates/NewsPostTemplate.js" /* webpackChunkName: "component---src-templates-news-post-template-js" */)
}

