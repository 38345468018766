import React, { useEffect } from "react"

const MessengerChat = () => {
  useEffect(() => {
    // Właściwości wtyczki czatu
    const chatbox = document.getElementById("fb-customer-chat")
    chatbox.setAttribute("page_id", "1078033495544877")
    chatbox.setAttribute("attribution", "biz_inbox")

    // Inicjalizacja SDK Facebooka
    window.fbAsyncInit = function () {
      // eslint-disable-next-line
      FB.init({
        xfbml: true,
        version: "v18.0",
      })
    }

    // Dodanie skryptu SDK do strony
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")

    // Oczekiwanie na załadowanie SDK
    window.addEventListener("load", function () {
      // eslint-disable-next-line
      FB.Event.subscribe("xfbml.render", function () {
        // Customizacje po załadowaniu wtyczki
        console.log("Facebook chat zainicjowany!")
      })
    })

    // Oczekiwanie na odmontowanie komponentu i usuwanie skryptów
    return () => {
      window.removeEventListener("load", function () {
        // eslint-disable-next-line
        FB.Event.unsubscribe("xfbml.render")
      })
    }
  }, [])
  return (
    <>
      <div id="fb-root" />
      <div id="fb-customer-chat" className="fb-customerchat" />
    </>
  )
}

export default MessengerChat
